<template>
    <div id="radar-wrapper">
        <RadarChart
            ref="radarChart"
            :chartData="chartData"
            @startupLogoClick="startupLogoClick"
        />
        <StartupDetailsPopover
            ref="startupDetailsPopover"
            :target="popoverTarget"
        />
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex"
    import StartupDetailsPopover from "@/components/radar/StartupDetailsPopover"
    import RadarChart from "@/components/radar/RadarChart"

    export default {
        name: "Radar",
        components: {
            StartupDetailsPopover,
            RadarChart,
        },

        props: {
            query: {
                type: Object,
                required: true,
            },
            hasInteractions: {
                type: [Boolean, Number],
                required: true,
            },
            includeHidden: {
                type: [Boolean, Number],
                required: true,
            },
            includeInactive: {
                type: [Boolean, Number],
                required: true,
            },
            includeAcquired: {
                type: [Boolean, Number],
                required: true,
            },
            notOnCrunchbase: {
                type: [Boolean, Number],
                required: true,
            },
            disableDefaultSorting: {
                type: [Boolean, Number],
                required: true,
            },
        },

        data: function () {
            return {
                settings: null,
                popoverTarget: 'radar-container',
            };
        },

        mounted() {
            if (Object.keys(this.customSettings).length) {
                this.processChangedSettings(this.customSettings);
            } else {
                this.getChartData();
            }
        },

        computed: {
            ...mapGetters({
                chartData: 'radar/radarInfo',
                customSettings: 'radar/customRadarSettings',
                defaultSettings: 'radar/defaultSettings',
            }),
        },

        watch: {
            query: {
                handler() {
                    this.getChartData()
                },
                deep: true
            },

            customSettings: {
                handler(newValue) {
                    this.processChangedSettings(newValue)
                },
                deep: true
            },

            hasInteractions() {
                this.getChartData()
            },

            includeHidden() {
                this.getChartData()
            },

            includeInactive() {
                this.getChartData()
            },

            includeAcquired() {
                this.getChartData()
            },

            notOnCrunchbase() {
                this.getChartData()
            },

            disableDefaultSorting() {
                this.getChartData()
            },
        },

        methods: {
            ...mapActions({
                fetchRadarInfo: 'radar/fetchRadarInfo',
            }),

            getChartData() {
                let query = this.query
                let settings = this.settings || this.defaultSettings
                let params = {
                    hasInteractions: this.hasInteractions,
                    includeHidden: this.includeHidden,
                    includeInactive: this.includeInactive,
                    includeAcquired: this.includeAcquired,
                    notOnCrunchbase: this.notOnCrunchbase,
                    disableDefaultSorting: this.disableDefaultSorting,
                }

                this.fetchRadarInfo({query, params, settings})
            },

            processChangedSettings(customSettings) {
                this.settings = customSettings

                this.getChartData()
            },

            startupLogoClick(startupId) {
                this.$refs.startupDetailsPopover.hidePopover()
                this.$refs.startupDetailsPopover.openPopover(startupId)
            }
        }
    }
</script>
